import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
    restaurantSettingsAddUpdateACTION,
    getRestaurantSettingsACTION,
    getRestaurantSurchargeSettingsACTION,
    getRestaurantGeneralSettingsACTION,
    getRestaurantReceiptSettingsACTION,
    restaurantGeneralSettingsAddUpdateACTION
} from '../actions/restaurantSetting.Actions'
import _ from "lodash";

const INITIAL_STATE = {
    restaurantSetting: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdaterestaurantSetting: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    restaurantSurchargeSetting: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    restaurantGeneralSetting: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    restaurantReceiptSetting: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdaterestaurantGeneralSetting: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


};

const restaurantSettingReducer = createSlice({
    name: "RestaurantSetting",
    initialState: INITIAL_STATE,
    reducers: {
        RESETRESTAURANTSETTING(state, action) {
            state.restaurantSetting = INITIAL_STATE.restaurantSetting
            state.insertUpdaterestaurantSetting = INITIAL_STATE.insertUpdaterestaurantSetting
            state.restaurantGeneralSetting = INITIAL_STATE.restaurantGeneralSetting
            state.insertUpdaterestaurantGeneralSetting = INITIAL_STATE.insertUpdaterestaurantGeneralSetting
        }
    },
    extraReducers: (builder) => {
        //------------------------------- getRestaurantSettingsACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getRestaurantSettingsACTION.fulfilled, (state, action) => {

            state.restaurantSetting.data = action.payload.data;
            state.restaurantSetting.apiMsg.status = action.meta.requestStatus;
            state.restaurantSetting.apiMsg.message = action.payload.message.message;

        });

        builder.addCase(getRestaurantSettingsACTION.pending, (state, action) => {

            state.restaurantSetting.apiMsg.status = action.meta.requestStatus;
         
        });

        builder.addCase(getRestaurantSettingsACTION.rejected, (state, action) => {

            state.restaurantSetting.apiMsg.message = action.error.message;
            state.restaurantSetting.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- getRestaurantSettingsACTION end -------------------------


        builder.addCase(getRestaurantSurchargeSettingsACTION.fulfilled, (state, action) => {


            state.restaurantSurchargeSetting.data = action.payload.data;
            state.restaurantSurchargeSetting.apiMsg.status = action.meta.requestStatus;
            state.restaurantSurchargeSetting.apiMsg.message = action.payload.message.message;


        });

        builder.addCase(getRestaurantSurchargeSettingsACTION.pending, (state, action) => {

            state.restaurantSurchargeSetting.apiMsg.status = action.meta.requestStatus;
         
        });

        builder.addCase(getRestaurantSurchargeSettingsACTION.rejected, (state, action) => {

            state.restaurantSurchargeSetting.apiMsg.message = action.error.message;
            state.restaurantSurchargeSetting.apiMsg.status = action.meta.requestStatus;
        });
        
        //------------------------------- restaurantSettingsAddUpdateACTION start -------------------------
        builder.addCase(restaurantSettingsAddUpdateACTION.fulfilled, (state, action) => {


            state.insertUpdaterestaurantSetting.apiMsg.status = action.meta.requestStatus;
            state.insertUpdaterestaurantSetting.apiMsg.message = action.payload.message.message;
        });
            builder.addCase(restaurantSettingsAddUpdateACTION.pending, (state, action) => {

                state.insertUpdaterestaurantSetting.apiMsg.status = action.meta.requestStatus;
            });
            builder.addCase(restaurantSettingsAddUpdateACTION.rejected, (state, action) => {

                state.insertUpdaterestaurantSetting.apiMsg.message = action.error.message;
                state.insertUpdaterestaurantSetting.apiMsg.status = action.meta.requestStatus;
            });
        //------------------------------- restaurantSettingsAddUpdateACTION end -------------------------



        builder.addCase(getRestaurantGeneralSettingsACTION.fulfilled, (state, action) => {
       
            state.restaurantGeneralSetting.data = action.payload.data;
            state.restaurantGeneralSetting.apiMsg.status = action.meta.requestStatus;
            state.restaurantGeneralSetting.apiMsg.message = action.payload.message.message;

        });

        builder.addCase(getRestaurantGeneralSettingsACTION.pending, (state, action) => {
     

            state.restaurantGeneralSetting.apiMsg.status = action.meta.requestStatus;
         
        });

        builder.addCase(getRestaurantGeneralSettingsACTION.rejected, (state, action) => {


            state.restaurantGeneralSetting.apiMsg.message = action.error.message;
            state.restaurantGeneralSetting.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- getRestaurantSettingsACTION end -------------------------
       
       
        builder.addCase(getRestaurantReceiptSettingsACTION.fulfilled, (state, action) => {


            state.restaurantReceiptSetting.data = action.payload.data;
            state.restaurantReceiptSetting.apiMsg.status = action.meta.requestStatus;
            state.restaurantReceiptSetting.apiMsg.message = action.payload.message.message;


        });

        builder.addCase(getRestaurantReceiptSettingsACTION.pending, (state, action) => {

            state.restaurantReceiptSetting.apiMsg.status = action.meta.requestStatus;
         
        });

        builder.addCase(getRestaurantReceiptSettingsACTION.rejected, (state, action) => {

            state.restaurantReceiptSetting.apiMsg.message = action.error.message;
            state.restaurantReceiptSetting.apiMsg.status = action.meta.requestStatus;
        });
        
       
        //------------------------------- restaurantSettingsAddUpdateACTION start -------------------------
        builder.addCase(restaurantGeneralSettingsAddUpdateACTION.fulfilled, (state, action) => {


            state.insertUpdaterestaurantGeneralSetting.apiMsg.status = action.meta.requestStatus;
            state.insertUpdaterestaurantGeneralSetting.apiMsg.message = action.payload.message.message;
        });
            builder.addCase(restaurantGeneralSettingsAddUpdateACTION.pending, (state, action) => {

                state.insertUpdaterestaurantGeneralSetting.apiMsg.status = action.meta.requestStatus;
            });
            builder.addCase(restaurantGeneralSettingsAddUpdateACTION.rejected, (state, action) => {

                state.insertUpdaterestaurantGeneralSetting.apiMsg.message = action.error.message;
                state.insertUpdaterestaurantGeneralSetting.apiMsg.status = action.meta.requestStatus;
            });
        //------------------------------- restaurantSettingsAddUpdateACTION end -------------------------

    }
});

export const { RESETRESTAURANTSETTING } = restaurantSettingReducer.actions;
export default restaurantSettingReducer.reducer;
